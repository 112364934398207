import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function pricecheckUseList(config) {
    // Use toast
    const toast = useToast()

    const refListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: 'id', label: 'ID', sortable: true},
        // { key: 'check_no', label: '审核编号 Util.setNo("SH")', sortable: true },
        // { key: 'order_id', label: '销售单Id', sortable: true },
        {key: 'order_no', label: '编号/订货单号', sortable: true},
        // { key: 'type_id', label: '单据类型 1线下  2线上', sortable: true },
        // { key: 'channel_id', label: '渠道ID 终端客户ID', sortable: true },
        {key: 'channel_name', label: '团队/平台', sortable: true},
        // { key: 'warehouse_id', label: '库存所在仓库ID', sortable: true },
        {key: 'warehouse_name', label: '销售仓库', sortable: true},
        // { key: 'sales_id', label: '销售员ID', sortable: true },
        {key: 'sales_name', label: '销售员名称', sortable: true},
        {key: 'sales_time', label: '售卖日期', sortable: true},
        {key: 'status', label: '状态', sortable: true},
        // { key: 'create_time', label: '添加时间', sortable: true },
        // { key: 'creator', label: '添加人ID', sortable: true },
        // { key: 'update_time', label: '更新时间', sortable: true },
        // { key: 'updator', label: '更新人ID', sortable: true },
        // { key: 'check_time', label: '审核时间', sortable: true },
        {key: 'checker', label: '审核人', sortable: true},
        {key: 'actions', label: '操作'},
    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('check_id')
    const isSortDirDesc = ref(true)
    const condition = ref({})
    const tabStatus = ref(1)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey, tabStatus], () => {
        refetchData()
    })

    const searchList = (ctx, callback) => {
        store
            .dispatch('pricecheck/search', {
                search_key: searchKey.value,
                start: start.value,
                limit: limit.value,
                order_by: orderBy.value,
                order_desc: isSortDirDesc.value === true ? 'desc' : '',
                status: tabStatus.value,
                check_no: condition.value.checkNo,
                name: condition.value.name,
                code: condition.value.code,
                order_no: isEmpty(config.order_no)?condition.value.orderNo:config.order_no,
            })
            .then(response => {
                const data = response.data.data
                const list = data.ext.list
                listTotals.value = data.ext.totals

                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '售价审核单列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,

        refetchData,
        condition,
        tabStatus,

        // Extra Filters
    }
}
